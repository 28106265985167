<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <div
          v-if="loaded"
          class="export-holder"
        >
          <vue-json-to-csv
            :json-data="usableRegistrantList"
          >
            <v-btn
              color="success"
              class="mr-6"
            >
              Export <i
                class="mdi mdi-export-variant"
                aria-hidden="true"
              />
            </v-btn>
          </vue-json-to-csv>
        </div>

        <v-card-title>
          Registrant Status
          <v-spacer />
          <v-spacer />
          <v-spacer />
          <v-spacer />

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          />
        </v-card-title>

        <v-data-table
          v-if="loaded"
          :headers="headers"
          :items="usableRegistrantList"
          class="elevation-1"
          multi-sort
          item-key="firstName"
          :search="search"
        />

        <div
          v-if="!loaded"
          class="spinner"
        >
          <div class="pixel-spinner">
            <div class="pixel-spinner-inner" />
          </div>
        </div>
      </base-card>
    </v-col>
  </v-row>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex'
  import VueJsonToCsv from 'vue-json-to-csv'

  export default {
    components: {
      VueJsonToCsv,
    },
    data () {
      return {
        search: '',
        loaded: false,
        headers: [
          { text: 'First Name', value: 'firstName' },
          { text: 'Last Name', value: 'lastName' },
          { text: 'Active Products', value: 'activeProducts' },
          { text: 'Car #', value: 'carNumbers' },
          { text: 'Log Book #', value: 'logBookNumbers' },
          { text: 'License #', value: 'licenseNumbers' },
        ],
        usableRegistrantList: [],
      }
    },
    created () {
      this.$store.dispatch('getAllUsers', 'Registrant')
    },
    computed: {
      ...mapGetters(['allUsers', 'getUserOrderCache']),
    },
    watch: {
      allUsers: async function (newUserList, oldUserList) {
        await this.fillUserOrderCache(newUserList.map((user) => user._id))
        this.loaded = true
        this.usableRegistrantList = this.allUsers
          .filter((user) => user.role === 'Registrant')
          .map((registrant) => {
            const completedOrders = this.getUserOrderCache(registrant._id)
            let activeProducts = []

            completedOrders.forEach((order) => {
              const products = [...order.club_products, ...order.products].filter((product) => product.isActive)
              activeProducts = [...activeProducts, ...products]
            })

            const carNumber = this.findOrderEntryNumber(activeProducts, completedOrders, 'Car #')
            const logBookNumber = this.findOrderEntryNumber(activeProducts, completedOrders, 'Log Book #')
            const LicenseNumber = this.findOrderEntryNumber(activeProducts, completedOrders, 'SCCA Licence #')

            return {
              firstName: registrant.contact?.givenName,
              lastName: registrant.contact?.surname,
              activeProducts: activeProducts.map((product) => product.name),
              carNumbers: carNumber,
              logBookNumbers: logBookNumber,
              licenseNumbers: LicenseNumber,
            }
          })
      },
    },
    mounted () {
    },
    methods: {
      ...mapActions(['fillUserOrderCache']),
      findOrderEntryNumber (activeProducts, orders, productEntryName) {
        const productWithOrderEntryValue = activeProducts.find((product) => product.product_entry.find((productEntry) => productEntry.name === productEntryName))

        if (!productWithOrderEntryValue) {
          return 'No Product'
        }

        const idOfProductEntryOrderEntryNumber = productWithOrderEntryValue.product_entry.find((entry) => entry.name === productEntryName)._id
        const orderWithValue = orders.find((order) => order.order_entry.find((orderEntry) => orderEntry.product_entry === idOfProductEntryOrderEntryNumber))

        if (!orderWithValue) {
          return 'Not Assigned'
        }
        const orderEntry = orderWithValue.order_entry.find((orderEntry) => orderEntry.product_entry === idOfProductEntryOrderEntryNumber)

        return orderEntry.value
      },
    },
  }
</script>
<style scoped>
.spinner{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 100px 0px;
}

.export-holder{
  padding: 20px;
}

.pixel-spinner, .pixel-spinner * {
      box-sizing: border-box;
    }

    .pixel-spinner {
      height: 70px;
      width: 70px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .pixel-spinner .pixel-spinner-inner {
      width: calc(70px / 7);
      height: calc(70px / 7);
      background-color: #FAFAFA;
      color: #FAFAFA;
      box-shadow: 15px 15px  0 0,
                  -15px -15px  0 0,
                  15px -15px  0 0,
                  -15px 15px  0 0,
                  0 15px  0 0,
                  15px 0  0 0,
                  -15px 0  0 0,
                  0 -15px 0 0;
      animation: pixel-spinner-animation 2000ms linear infinite;
    }

    @keyframes pixel-spinner-animation {
      50% {
        box-shadow: 20px 20px 0px 0px,
                    -20px -20px 0px 0px,
                    20px -20px 0px 0px,
                    -20px 20px 0px 0px,
                    0px 10px 0px 0px,
                    10px 0px 0px 0px,
                    -10px 0px 0px 0px,
                    0px -10px 0px 0px;
      }
      75% {
        box-shadow: 20px 20px 0px 0px,
                    -20px -20px 0px 0px,
                    20px -20px 0px 0px,
                    -20px 20px 0px 0px,
                    0px 10px 0px 0px,
                    10px 0px 0px 0px,
                    -10px 0px 0px 0px,
                    0px -10px 0px 0px;
      }
      100% {
        transform: rotate(360deg);
      }
    }
</style>
